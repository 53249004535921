<template>
  <svg viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_101_2)">
      <path d="M13.29 7.00647L7.29004 1.00647L1.29 7.00647" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_101_2">
        <rect width="14" height="8" fill="white" transform="translate(14 8) rotate(-180)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconChevronUp'
}
</script>
